import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import Heading from "../components/Heading"

const pageQuery = graphql`
  query Imprint {
    allGraphCmsImpressum {
      nodes {
        text {
          html
        }
      }
    }
  }
`

export default function Imprint() {
  const {
    allGraphCmsImpressum: { nodes },
  } = useStaticQuery(pageQuery)
  return (
    <Layout title="Katharina Kohler | Imprint" description="Katharina Kohler - M.Sc. psychologist and artist, architectural psychology, digital and analog art, Berlin, Germany">
      <Heading>Imprint</Heading>
      <div
        className="space-y-4"
        dangerouslySetInnerHTML={{
          __html: nodes[0].text.html,
        }}
      ></div>
    </Layout>
  )
}
